import PropTypes from 'prop-types'
import { Fragment } from 'react'
import cx from 'classnames'

import classNames from './styles.module.scss'
import { Typography } from 'common/widgets'
import formatPrice from 'common/utils/formatPrice'


PromotionCard.propTypes = {
  promotions: PropTypes.array.isRequired,
  withMargin: PropTypes.bool,
  savings: PropTypes.bool,
}

PromotionCard.defaultProps = {
  withMargin: false,
  savings: false,
}

export default function PromotionCard({ promotions, withMargin, savings }) {
  return (
    <Fragment>
      {promotions.map(promotion => (
        <div className={cx(classNames.textRow, withMargin ? classNames.margin : classNames.noMargin, savings && classNames.savings)} key={promotion.uuid}>
          <span>
            <Typography
              variant="small-gray-cart"
              color="pink"
            >
              {!savings ? promotion.label : promotion.label.replace(promotion?.price?.value, (v) => formatPrice(v))}
            </Typography>
          </span>
          {!savings && (
            <span>
              <Typography variant="small-gray-cart" transform="uppercase" color="pink">
                {promotion.price.value && `${formatPrice(Math.ceil(promotion.price.value)).toString().replace('-', '– ')} ${promotion.price.currency}`}
              </Typography>
            </span>
          )}
        </div>
      ))}
    </Fragment>
  )
}
