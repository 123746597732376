import { Checkbox } from 'antd'
import PropTypes from 'prop-types'


import { Typography, Info } from 'common/widgets'
import classNames from './styles.module.scss'


CheckboxInput.propTypes = {
  onChange: PropTypes.func,
  boxLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  value: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  tooltip: PropTypes.string,
}

CheckboxInput.defaultProps = {
  boxLabel: '',
  value: false,
  disabled: false,
  required: false,
  onChange: undefined,
  className: undefined,
  tooltip: undefined,
  variant: 'filter',
}

export default function CheckboxInput({ onChange, boxLabel, value, disabled, className, variant, required, tooltip, ...rest }) {
  return (
    <span onClick={e => {
      e.stopPropagation()
    }} className={classNames.wrapper}
    >
      <Checkbox
        {...rest}
        checked={value}
        disabled={disabled}
        onChange={onChange}
        data-testid={rest.name}
      >
        <Typography variant={variant}>
          {boxLabel}
          {required && ' *'}
          {tooltip && <Info title={tooltip} className={classNames.info} />}
        </Typography>
      </Checkbox>
    </span>
  )
}
