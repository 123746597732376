import PropTypes from 'prop-types'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations, interpolate } from 'common/language'


CardTextContent.propTypes = {
  studentAge: PropTypes.object,
  groupSize: PropTypes.object,
  languageNiveau: PropTypes.object,
}

CardTextContent.defaultProps = {
  studentAge: {},
  groupSize: {},
  languageNiveau: undefined,
}

export default function CardTextContent({ studentAge, groupSize, languageNiveau }) {
  const { gettext, ngettext } = useTranslations()
  return (
    <div>
      {(studentAge.min || studentAge.avg) && (
        <div className={classNames.textRow}>
          <span>
            <Typography variant="small-gray" color="light-black">
              {gettext('Student Age')}
            </Typography>
          </span>
          <span>
            <Typography variant="small">
              {studentAge.min && interpolate(gettext('Minimum %s'), [studentAge.min])}
              {studentAge.min && studentAge.avg && ' / '}
              {studentAge.avg && interpolate(gettext('Average %s'), [studentAge.avg])}
              {' '}
              {ngettext('Year', 'Years', studentAge.min || studentAge.avg)}
            </Typography>
          </span>
        </div>
      )}
      {(groupSize.max || groupSize.avg) && (
        <div className={classNames.textRow}>
          <span>
            <Typography variant="small-gray" color="light-black">
              {gettext('Group Size')}
            </Typography>
          </span>
          <span>
            <Typography variant="small">
              {groupSize.max && interpolate(gettext('Maximum %s'), [groupSize.max])}
              {groupSize.max && groupSize.avg && ' / '}
              {groupSize.avg && interpolate(gettext('Average %s'), [groupSize.avg])}
              {' '}
              {ngettext('Student', 'Students', groupSize.max || groupSize.avg)}
            </Typography>
          </span>
        </div>
      )}
      {(languageNiveau.min || languageNiveau.max) && (
        <div className={classNames.textRow}>
          <span>
            <Typography variant="small-gray" color="light-black">
              {gettext('Language Niveau')}
            </Typography>
          </span>
          <span>
            <Typography variant="small">
              {languageNiveau.min && `${languageNiveau.min.name} (${languageNiveau.min.abbreviation})`}
              {languageNiveau.min && languageNiveau.max && ' - '}
              {languageNiveau.max && `${languageNiveau.max.name} (${languageNiveau.max.abbreviation})`}
            </Typography>
          </span>
        </div>
      )}
    </div>
  )
}
