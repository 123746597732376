import { autobind } from 'core-decorators'
import PropTypes from 'prop-types'
import { Component, Children, cloneElement } from 'react'
import dynamic from 'next/dynamic'

import { moveBackAfterClose, addFixedToBody } from 'common/utils/modalScroll'


const ModalWrapper = dynamic(() => import('./ModalWrapper'), { ssr: false })

const propTypes = {
  children: PropTypes.object,
  onCloseCallback: PropTypes.func,
  isNestedModal: PropTypes.bool,
}

const defaultProps = {
  children: undefined,
  onCloseCallback: undefined,
  isNestedModal: false,
}


export default class ModalTrigger extends Component {
  state = {
    toggled: false,
  }

    @autobind
  open(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ toggled: true })
    addFixedToBody()
  }

    @autobind
    close(e) {
      e.stopPropagation()
      this.setState({ toggled: false })
      if(!this.props.isNestedModal) {
        moveBackAfterClose()
      }
      this.props.onCloseCallback && this.props.onCloseCallback()
    }

    componentWillUnmount() {
      moveBackAfterClose()
    }

    render() {
      const { children } = this.props

      // ensure that we have only one child (control element)
      let child = cloneElement(Children.only(children), { onClick: this.open, key: 'modal-control' })
      return [
        child,
        <ModalWrapper {...this.props} show={this.state.toggled} onHide={this.close} key="modal-dialog" withTrigger />,
      ]
    }
}

ModalTrigger.propTypes = propTypes
ModalTrigger.defaultProps = defaultProps
