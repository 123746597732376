import PropTypes from 'prop-types'
import { useMemo, useCallback, useEffect, useState } from 'react'
import { Row, notification } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { ResourceType } from '@ds-frontend/resource'
import { useRouter } from 'next/router'
import API from 'api'

import { Collapse, Panel, Button, Typography, QuoteCard } from 'common/widgets'
import OptionHeader from './widgets/OptionHeader'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'
import ModalTrigger from 'common/modals/ModalTrigger'
import QuoteForm from './widgets/QuoteForm'
import AddAnotherOption from './widgets/AddAnotherOption'
import { checkCartExpiredDate, deleteCartExpiredDate } from 'common/utils/cartDate'
import ContinueBooking from './widgets/ContinueBooking'
import useSessionStorage from 'hooks/useSessionStorage'
import { lg } from 'common/utils/breakpoints'


CartView.propTypes = {
  cartData: ResourceType.isRequired,
  uuid: PropTypes.string,
  headerHeight: PropTypes.number.isRequired,
  toggleCart: PropTypes.func,
  schoolName: PropTypes.string.isRequired,
  withSchoolMock: PropTypes.bool,
  onHide: PropTypes.func,
  mobileTitleText: PropTypes.string,
}

CartView.defaultProps = {
  withSchoolMock: false,
  onHide: undefined,
  mobileTitleText: undefined,
  toggleCart: undefined,
  uuid: undefined,
}

export default function CartView({ cartData, uuid, toggleCart, schoolName, headerHeight, withSchoolMock, onHide, mobileTitleText }) {
  const { gettext, languageCode } = useTranslations()
  const [, setBookingData] = useSessionStorage('bookingData')
  const { pathname, query, push, locale } = useRouter()
  const [ cLength, setLength ] = useState(0)

  const cart = useMemo(() => get(cartData, 'data', {}), [cartData.data])
  const cartKeyArray = useMemo(() => Object.keys(cart), [cart])
  const removeOption = useCallback((uuid) => {
    cartData.setData(omit(cartData.data, [uuid]))
    if(uuid === query.option) {
      if(query.option) {
        push({
          pathname: pathname,
          query: { ...omit(query, ['option']) },
        }, undefined, { shallow: true, scroll: false })
      }
    }
  }, [cartData.data, query.option])
  const isSchoolMock = useMemo(() => typeof window !== 'undefined' && withSchoolMock && cartKeyArray.length < 3 && cartKeyArray.every(cartUUID => cartUUID !== uuid), [cartKeyArray, uuid])
  let isMobile = false
  if(typeof window !== 'undefined') {
    isMobile = window.innerWidth <= lg
  }
  useEffect(() => {
    const isCart = checkCartExpiredDate()
    if(!isCart && Boolean(cartKeyArray.length)) {
      cartData.clear()
      notification.error({
        message: gettext('Error'),
        description: gettext('Your cart session has expired'),
        duration: 5,
      })
    } else if(isCart && Boolean(cartKeyArray.length)) {
      let localizedCart = cart
      Object.entries(cart).forEach(([key, value]) => {
        if(value.locale !== languageCode) {
          const schoolSlug = get(value, `hrefLangData.${languageCode}.school_slug`, value.school.slug)
          return API.post(`api/v2/public/schools/${schoolSlug}/options`, value)
            .then(resp => {
              localizedCart = {
                ...localizedCart,
                [key]: {
                  ...value,
                  ...resp,
                  locale: languageCode,
                },
              }
              cartData.setData(localizedCart)
            })
        }
      })
    }
  }, [])

  const handleVoucher = useCallback((optionUUID) => {
    return function voucherPost(data) {
      const cartOption = cart[optionUUID]
      const schoolSlug = get(cartOption, `hrefLangData.${locale}.school_slug`)
      return API.post(
        `api/v2/public/schools/${schoolSlug}/options`,
        { ...cartOption,
          voucher_code: data.voucher_code },
        { headers: { 'Accept-Language': locale } }
      )
        .then(resp => {
          cartData.setData({
            ...cart,
            [optionUUID]: {
              ...cartOption,
              ...resp,
            },
          })
        })
        .catch(err => ({ voucher_code: err?.non_field_errors?.join(' ') }))
    }
  }, [cart, locale])

  useEffect(() => {
    if(cartKeyArray.length === 0) {
      deleteCartExpiredDate()
    }
    // add cart items length key to reinitialize buttons disabled state (NEXT JS static hydration issue)
    setLength(cartKeyArray.length || 0)
  }, [cartKeyArray.length])
  const panels = useMemo(() => (
    cartKeyArray.map((optionUUID, ind) => (
      <Panel
        key={optionUUID}
        id={optionUUID}
        header={<OptionHeader school_name={cart[optionUUID].school.name} urlData={get(cart[optionUUID], `hrefLangData[${languageCode}]`, '')} withLink={optionUUID !== uuid} order={ind + 1} removeOption={() => removeOption(optionUUID)} optionUUID={optionUUID} />}
        className={classNames.cartPanel}
      >
        <QuoteCard option={{ exchange_rate: cart[optionUUID].exchange_rate, ...cart[optionUUID].shopping_cart }} handleVoucher={handleVoucher(optionUUID)} initialVoucher={cart[optionUUID]?.voucher_code} hidePartyOptions withVoucher>
          <div className={classNames.footer}>
            <ModalTrigger
              title={gettext('Continue booking')}
              component={ContinueBooking}
              cart={cart[optionUUID]}
              modalClassName={classNames.modalWrapper}
              setBookingData={setBookingData}
              isNestedModal={isMobile}
              state={{
                id: optionUUID,
              }}
            >
              <Button id={optionUUID} className={classNames.button}>{gettext('continue booking')}</Button>
            </ModalTrigger>
          </div>
        </QuoteCard>
      </Panel>
    ))
  ), [cart, uuid, removeOption])
  return (
    <div className={classNames.cartFixedBlock} style={{ top: headerHeight }}>
      <Row className={classNames.cartHeader}>
        <Button onClick={onHide || toggleCart} type="icon" icon="arrow-left" classNameIcon={classNames.arrowButton} />
        <Typography variant="h4">{mobileTitleText || gettext('Options')}</Typography>
        <span className={classNames.rightBlock} />
      </Row>
      <Collapse
        defaultActiveKey={[uuid]}
        key={uuid}
        className={classNames.cartBody}
      >
        {panels}
        {isSchoolMock && (
          <div className={classNames.mockOption}>
            <OptionHeader school_name={schoolName} order={cartKeyArray.length + 1} />
          </div>
        )}
      </Collapse>
      <Row className={classNames.cartFooter} key={cLength}>
        <ModalTrigger
          component={AddAnotherOption}
          modalClassName={classNames.anotherOptionWrapper}
          title="Modal"
          width={1280}
          isNestedModal={isMobile}
        >
          <Button
            type="primary-outlined"
            className={classNames.buttonCart}
            disabled={cartKeyArray.length >= 3}
          >
            {gettext('add another option')}
          </Button>
        </ModalTrigger>
        {!isEmpty(cart) && (
          <ModalTrigger component={QuoteForm} modalClassName={classNames.modalWrapper} title="Modal" cart={cart} languageCode={languageCode} isNestedModal={isMobile}>
            <Button type="primary-outlined" className={classNames.buttonCart}>{gettext('get quote')}</Button>
          </ModalTrigger>
        )}
      </Row>
    </div>
  )
}
