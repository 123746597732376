import { Fragment } from 'react'
import Script from 'next/script'

import { useTranslations } from 'common/language'
import classNames from './styles.module.scss'


export default function eTermin() {
  const { languageCode } = useTranslations()
  return (
    <Fragment>
      <iframe title="eTermin" id="etifr" className={classNames.frame} src={`https://www.eTermin.net/${process.env.ETERMIN_ID}?lang=${languageCode}`} height="1600px" width="100%" scrolling="no" frameBorder="0" />
      <Script strategy="beforeInteractive" src="https://www.eTermin.net/js/resizecustomersitescroll.min.js" type="text/javascript" />
    </Fragment>
  )
}
