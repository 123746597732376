import PropTypes from 'prop-types'


MultiValue.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
}

export default function MultiValue({ children, index }) {
  return (
    <span>
      {index > 0 ? ', ' : ''}
      {children}
    </span>
  )
}
