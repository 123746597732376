export function moveBackAfterClose() {
  if(typeof document === 'undefined') {
    return
  }
  if(document.body.style.position === 'fixed') {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.height = 'auto'
    document.documentElement.style.height = 'auto'
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}

export function addFixedToBody() {
  if(typeof document === 'undefined' || document.body.style.position === 'fixed') {
    return
  }
  const scrollY = window.scrollY
  document.body.style.position = 'fixed'
  document.body.style.top = `-${scrollY}px`
  document.body.style.height = window.innerHeight - 1 + 'px'
  document.documentElement.style.height = window.innerHeight - 1 + 'px'
}
