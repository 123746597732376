import { useMemo } from 'react'

import { useTranslations } from 'common/language'


export default function Error({ kind }) {
  const { gettext } = useTranslations()
  const kinds = useMemo(() => ({
    required: gettext('Please enter value'),
    requiredOption: gettext('Please select option'),
    email: gettext('Please enter valid email address, e.g: name@example.com'),
  }), [gettext])
  return kinds[kind]
}
