import PropTypes from 'prop-types'


const ImageType = PropTypes.shape({
  data: PropTypes.shape({
    name: PropTypes.string,
    alttext: PropTypes.string,
    caption: PropTypes.string,
  }),
  bynder: PropTypes.shape({
    name: PropTypes.string,
    derivatives: PropTypes.shape({
      thumbnail: PropTypes.string,
      webImage: PropTypes.string,
      header: PropTypes.string,
      webbig: PropTypes.string,
    }),
    files: PropTypes.shape({
      thumbnail: PropTypes.shape({
        url: PropTypes.string,
      }),
      webImage: PropTypes.shape({
        url: PropTypes.string,
      }),
      header: PropTypes.shape({
        url: PropTypes.string,
      }),
      webbig: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
})

export default ImageType
