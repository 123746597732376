import { useField } from 'react-final-form'


export default function DependentField({ children, dependencies }) {
  const fieldKind = dependencies.dependent_on_field_kind
  if(!fieldKind) {
    return children
  }
  let fieldValue = dependencies.dependent_on_field_value
  fieldValue = Array.isArray(fieldValue) ? fieldValue : [fieldValue]
  const fieldCondition = dependencies.dependent_on_field_condition
  const { input } = useField(fieldKind)
  const inputValue = Array.isArray(input.value) ? input.value : [input.value]
  const isValid = inputValue.some(firstValue => fieldValue.some(secondValue => {
    switch (fieldCondition) {
    case 'less_than':
      return firstValue < secondValue
    case 'more_than':
      return firstValue > secondValue
    default:
      return firstValue === secondValue
    }
  }))
  return isValid ? children : null
}
