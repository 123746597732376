import { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import classNames from './styles.module.scss'


TextInput.propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

TextInput.defaultProps = {
  inputClassName: 'input-custom',
  readOnly: false,
  size: 'medium',
  placeholder: '',
  pattern: undefined,
  required: undefined,
  disabled: undefined,
  error: false,
  value: '',
  name: undefined,
}

export default function TextInput({ onChange, inputClassName, disabled, error, size, ...props }) {
  const handleChange = useCallback((e) => onChange(e.target.value), [onChange])
  return (
    <input
      {...props}
      disabled={disabled}
      className={cx(
        classNames.input,
        classNames[size],
        inputClassName,
        disabled && classNames.disabled,
        error && classNames.error,
        props.value && classNames.withValue,
      )}
      onChange={handleChange}
    />
  )
}
