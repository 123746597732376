import { useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'


BorderFieldLayout.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  withoutError: PropTypes.bool,
  inputComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  meta: PropTypes.object,
  input: PropTypes.object,
  prefix: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.string,
}

BorderFieldLayout.defaultProps = {
  label: undefined,
  required: false,
  disabled: false,
  size: 'medium',
  withoutError: false,
  prefix: undefined,
  meta: {},
  input: {},
}

export default function BorderFieldLayout({
  label,
  prefix,
  required,
  inputComponent: InputComponent,
  meta,
  input,
  disabled,
  withoutError,
  size,
  ...rest
}) {
  const error = useMemo(() => {
    if(meta.submitError && !meta.dirtySinceLastSubmit) {
      return meta.submitError
    }
    if(meta.error && meta.touched) {
      return meta.error
    }
  }, [meta.error, meta.touched, meta.dirtySinceLastSubmit, meta.submitError])
  const formattedError = useMemo(() => Array.isArray(error) ? error[0] : error, [error])
  return (
    <div className={cx('form-group', meta.submitError && !meta.dirtySinceLastSubmit && 'is-submit-error')}>
      <div className="control-field">
        <div className={cx(
          classNames.fieldWrapper,
          error && classNames.error,
          disabled && classNames.disabled,
        )}
        >
          {prefix && <div className="control-prefix">{prefix}</div>}
          <InputComponent
            required={required}
            error={error}
            disabled={disabled}
            size={size}
            {...rest}
            {...input}
          />
          {label && (
            <label className={cx(
              classNames.label,
              classNames[size],
              input.value && classNames.withValue,
              meta.active && classNames.active,
              error && classNames.error,
            )}
            >
              {label}
              {required && <span className="control-asterisk"> * </span>}
            </label>
          )}
          <fieldset
            aria-hidden="true"
            className={cx(
              classNames.borderWrapper,
              meta.active && classNames.active,
              error && classNames.error,
            )}
          >
            <legend
              className={cx(
                classNames.legend,
                input.value && classNames.withValue,
                meta.active && classNames.active,
              )}
            >
              {label}
              {required && <span className="control-asterisk"> * </span>}
            </legend>
          </fieldset>
        </div>
        {!withoutError && (
          <Typography variant="error" color="pink" className={cx(classNames.customError, size === 'xlarge' && classNames.largeError)}>
            {formattedError && (
              <Fragment>
                <FontAwesomeIcon icon="exclamation-circle" className={classNames.errorCircle} />
                {formattedError}
              </Fragment>
            )}
          </Typography>
        )}
      </div>
    </div>
  )
}
