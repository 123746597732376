import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'

import classNames from './styles.module.scss'
import { Info, Typography } from 'common/widgets'
import formatPrice from 'common/utils/formatPrice'


ServiceCard.propTypes = {
  type: PropTypes.object.isRequired,
  contentClassName: PropTypes.string,
}

ServiceCard.defaultProps = {
  contentClassName: undefined,
}

export default function ServiceCard({ type, contentClassName }) {
  const [ tooltipRef, setRef ] = useState(null)
  const services = useMemo(() => (
    type.items.map(service => (
      <div className={classNames.textRow} key={service.label}>
        <span>
          <Typography
            variant="small-gray-cart"
            color="black"
          >
            {service.label}
            {service.tooltip && (
              <Info
                title={service.tooltip}
                className={classNames.cartTooltip}
                getPopupContainer={tooltipRef ? () => tooltipRef.parentNode : undefined}
              />
            )}
          </Typography>
        </span>
        <span>
          {!isEmpty(service.price) && (
            <Typography
              variant="small-gray-cart"
              transform="uppercase"
            >
              {formatPrice(service.price.value)} {service.price.currency}
            </Typography>
          )}
        </span>
      </div>
    ))
  ), [type.items, tooltipRef])
  return (
    <div className={classNames.cartItemWrapper} ref={ref => setRef(ref)}>
      {(type.title || type.additional_title) && (
        <Typography
          variant="small-gray-cart"
          className={classNames.title}
          color="black"
        >
          <span>{type.title}</span> {type.additional_title}
        </Typography>
      )}
      {type.sub_title && (
        <Typography variant="small-gray-cart" color="black">
          {type.sub_title}
        </Typography>
      )}
      <div className={cx(classNames.textWrapper, contentClassName)}>
        {services}
      </div>
    </div>
  )
}
