import { useMemo } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import cx from 'classnames'

import classNames from './styles.module.scss'


Loading.propTypes = {
  isLoading: PropTypes.bool,
  fontSize: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
}

Loading.defaultProps = {
  isLoading: false,
  fontSize: 24,
  children: null,
  className: undefined,
}

export default function Loading({ isLoading, children, fontSize, className, ...props }) {
  const antIcon = useMemo(_ => <LoadingOutlined style={{ fontSize }} spin />, [fontSize])
  return (
    isLoading ? <Spin indicator={antIcon} className={cx(classNames.loading, className)} {...props} /> : children
  )
}
