import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import BaseFieldLayout from './BaseFieldLayout'
import BorderFieldLayout from './BorderFieldLayout'


export default function BaseFieldHOC(Component, border) {
  FieldComponent.propTypes = {
    validate: PropTypes.func,
    parse: PropTypes.func,
    defaultValue: PropTypes.any,
    name: PropTypes.string.isRequired,
  }

  FieldComponent.defaultProps = {
    validate: noop,
    defaultValue: undefined,
    parse: value => value,
  }

  const Layout = border ? BorderFieldLayout : BaseFieldLayout

  function FieldComponent({ parse, defaultValue, validate, ...props }) {
    return (
      <Field
        name={props.name}
        validate={validate}
        defaultValue={defaultValue}
        parse={parse}
      >
        {fieldProps => (
          <Layout {...props} {...fieldProps} inputComponent={Component} />
        )}
      </Field>
    )
  }

  return FieldComponent
}
