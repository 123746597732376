import cx from 'classnames'
import PropTypes from 'prop-types'

import classNames from './styles.module.scss'


Divider.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
}

Divider.defaultProps = {
  variant: 'page',
  className: undefined,
}

export default function Divider({ variant, className }) {
  return (
    <div className={cx(classNames[variant], className)} />
  )
}
