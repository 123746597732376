import { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import classNames from '../TextInput/styles.module.scss'


TextAreaInput.propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  required: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

TextAreaInput.defaultProps = {
  inputClassName: 'input-custom',
  placeholder: '',
  disabled: false,
  error: false,
  required: false,
  rows: undefined,
  cols: undefined,
  value: '',
  name: undefined,
  size: 'medium',
}


export default function TextAreaInput({ onChange, inputClassName, disabled, error, size, ...props }) {
  const handleChange = useCallback((e) => onChange(e.target.value), [onChange])
  return (
    <div className={cx(classNames.textAreaWrapper, classNames[size])}>
      <textarea
        {...props}
        disabled={disabled}
        className={cx(classNames.input, classNames[size], inputClassName, disabled && classNames.disabled, error && classNames.error)}
        onChange={handleChange}
      />
    </div>
  )
}
