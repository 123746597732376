import classNames from './styles.module.scss'
import { useField } from 'react-final-form'
import { Row, Col } from 'antd'
import { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { SelectField } from 'common/forms'
import { Button, Typography, Info } from 'common/widgets'
import { useTranslations } from 'common/language'
import formatPrice from 'common/utils/formatPrice'


PartyOption.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.shape({
    subscription_options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        value: PropTypes.number,
        currency: PropTypes.string,
        reference: PropTypes.shape({
          value: PropTypes.string,
          currency: PropTypes.string,
        }),
      }),
    })),
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default function PartyOption({ name, item }) {
  const [ tooltipRef, setRef ] = useState(null)
  const { gettext } = useTranslations()
  useField(`${name}.third_party_service_id`, { defaultValue: item.id })
  const { input: selectInput, meta } = useField(`${name}.third_party_service_subscription_option_id`)
  const { input: checkboxInput } = useField(`${name}.is_selected`)
  const price = useMemo(_ => {
    if(selectInput.value) {
      return item.subscription_options.find(i => i.id === selectInput.value).price
    }
    return item.subscription_options[0]?.price || {}
  }, [selectInput.value, item.subscription_options])
  const priceCurrency = useMemo(() => get(price, 'reference.currency', price.currency), [price])
  const priceValue = useMemo(() => get(price, 'reference.value', price.value), [price])
  const handleSelect = useCallback(() => checkboxInput.onChange(!checkboxInput.value), [checkboxInput.value])
  return (
    <div className={classNames.formWrapper} ref={ref => setRef(ref)}>
      <div className={classNames.formRow}>
        <Row>
          <Col lg={18} span={24}>
            <div>
              <Typography
                className={classNames.text}
                variant="h4-card"
              >
                {item.name}
                {item.description && <Info title={item.description} getPopupContainer={tooltipRef ? () => tooltipRef.parentNode : undefined} />}
              </Typography>
            </div>
            <div className={classNames.selectWrapper}>
              <SelectField
                name={`${name}.third_party_service_subscription_option_id`}
                options={item.subscription_options}
                labelKey="name"
                valueKey="id"
                withoutError
                defaultValue={item.subscription_options[0]?.id}
                size="large"
                disabled={Boolean(checkboxInput.value)}
              />
            </div>
          </Col>
          <Col lg={6} span={24} className={classNames.rightBlock}>
            <Button
              type={checkboxInput.value ? 'secondary' : 'primary'}
              onClick={handleSelect}
            >
              {checkboxInput.value ? gettext('Unselect') : gettext('Select')}
            </Button>
            {(priceCurrency || priceValue) && (
              <div className={classNames.priceText}>
                {!meta.modified && (
                  gettext('From')
                )}
                <span>
                  {' '}
                  {priceCurrency}
                  {' '}
                  {formatPrice(priceValue)}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}
