import useCart from 'hooks/useCart'


export default function withCartHOC(Component) {
  return function withCart(props) {
    const cartData = useCart()
    return (
      <Component {...props} cartData={cartData} />
    )
  }
}
