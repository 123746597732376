import { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import CheckboxInput from '../CheckboxInput/CheckboxInput'
import classNames from './styles.module.scss'


CheckboxArrayInput.propTypes = {
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  countKey: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  boxLabel: PropTypes.oneOfType([
    PropTypes.func,
  ]),
  onChange: PropTypes.func.isRequired,
}

CheckboxArrayInput.defaultProps = {
  valueKey: 'uuid',
  labelKey: 'name',
  countKey: 'count',
  value: [],
  options: [],
  variant: undefined,
  size: undefined,
  disabled: false,
  boxLabel: (item, labelKey) => item[labelKey],
  className: undefined,
}

export default function CheckboxArrayInput({ value, options, valueKey, labelKey, onChange, disabled, countKey, className, boxLabel, size, variant }) {
  const handleChange = useCallback((itemValue, key) => {
    itemValue = itemValue.target.checked
    if(itemValue) {
      return onChange([ ...value, key ])
    }
    return onChange(value.filter(i => i !== key))
  }, [value])
  return (
    <div className={cx(className, classNames[size])}>
      {options.map(item => {
        const isChecked = value.includes(item[valueKey])
        return (
          <div key={item[valueKey]}>
            <CheckboxInput
              variant={variant}
              boxLabel={boxLabel(item, labelKey, countKey)}
              value={isChecked}
              onChange={value => handleChange(value, item[valueKey])}
              disabled={disabled || (item[countKey] === 0 && !isChecked)}
              name={item[valueKey]}
            />
          </div>
        )
      })}
    </div>
  )
}
