import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import cx from 'classnames'
import { createFilter } from 'react-select'
import { FormSpy } from 'react-final-form'

import { Container, Button, Typography } from 'common/widgets'
import { TextField, PhoneField, TextAreaField, DateField, MaskTimeField, SelectField, CheckboxArrayField, RadiosField, CheckboxField, SwitchField } from 'common/forms'
import SubsectionField from './SubsectionField'
import SpacingField from './SpacingField'
import { required, requiredOption, email, composeValidators, requiredWithoutFalse } from 'common/forms/validation'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'
import DependentField from './DependentField'


function requiredByKind(kind) {
  switch (kind) {
  case 'select':
    return requiredOption
  case 'radio':
    return requiredWithoutFalse
  default:
    return required
  }
}

const detectError = () => {
  if(typeof window !== 'undefined') {
    setTimeout(() => {
      const element = document.querySelector('.is-submit-error')
      element && element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }, 100)
  }
}

Forms.propTypes = {
  data: PropTypes.shape({
    form_kind: PropTypes.string,
    button_label: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  valid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  withoutPadding: PropTypes.bool,
  formData: PropTypes.shape({
    form_fields: PropTypes.array,
    button_label: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  excludeFromOptional: PropTypes.array,
  columnAlign: PropTypes.bool,
  compactTitle: PropTypes.bool,
}

Forms.defaultProps = {
  data: {},
  handleSubmit: undefined,
  isLoading: false,
  title: '',
  subtitle: '',
  withoutPadding: false,
  excludeFromOptional: [],
  columnAlign: false,
  compactTitle: false,
}

const fields = {
  singleline: TextField,
  hidden: () => null,
  email: TextField,
  multiline: TextAreaField,
  date: DateField,
  time: MaskTimeField,
  select: SelectField,
  checkboxArray: CheckboxArrayField,
  checkbox: CheckboxField,
  radio: RadiosField,
  phone_number: PhoneField,
  subsection: SubsectionField,
  spacing: SpacingField,
  toggle: SwitchField,
}

export default function Forms({ data, handleSubmit, valid, formData, isLoading, title, subtitle, withoutPadding, excludeFromOptional, columnAlign, compactTitle }) {
  const { gettext } = useTranslations()
  const fieldData = useMemo(() => get(formData, 'form_fields', []).map((item, index) => {
    const type = item.input_type === 'checkbox' && !isEmpty(item.choices) ? 'checkboxArray' : item.input_type
    const valueLabelKey = ['checkboxArray', 'radio', 'select'].includes(type) ? { valueKey: 'value', labelKey: 'label' } : {}
    const selectProps = type === 'select'
      ? { isClearable: !item.required,
        isSearchable: true,
        hideSelectedOptions: true,
        filterOption: createFilter({
          matchFrom: 'any', stringify: option => option.label }),
        instanceId: `${title}-select-${index}` }
      : {}
    const Field = fields[type] || (() => null)
    const validate = [
      item.required && requiredByKind(item.input_type),
      item.input_type === 'email' && email,
    ].filter(i => Boolean(i))
    const validators = (type !== 'subsection' && type !== 'spacing') ? { validate: composeValidators(...validate) } : {}
    const col = item.size === 'full' ? { span: 24 } : { span: 24, sm: 12 }
    const optional = item.required || ['section', 'subsection'].includes(item.input_type) || excludeFromOptional.includes(item.kind) ? '' : ` (${gettext('Optional')})`
    const label = item.label ? item.label + optional : ''
    const boxLabel = item.input_type === 'checkbox' ? { boxLabel: <span dangerouslySetInnerHTML={{ __html: label }} /> } : {}
    return (
      <DependentField key={`${item.kind}+${index}`} dependencies={item.dependencies}>
        <Col {...col} className={cx(classNames.fieldCol, type === 'subsection' && item.size !== 'full' && classNames.nextAlignRight)} data-kind={item.kind}>
          <Field
            label={item.input_type !== 'checkbox' ? label : undefined}
            name={item.kind}
            options={item.choices}
            {...validators}
            {...valueLabelKey}
            size="xlarge"
            placeholder=""
            rows={item.input_type === 'multiline' ? 10 : undefined}
            {...boxLabel}
            variant={`form-${item.input_type}`}
            className={item.input_type === 'checkbox' ? classNames.checkWrapper : undefined}
            defaultValue={(item.input_type === 'phone_number' && item.required) ? '+41' : item.default_value}
            tooltip={item.tooltip}
            {...selectProps}
            type={item.input_type === 'email' ? 'email' : undefined}
            data-testid={item.input_type}
          />
        </Col>
      </DependentField>
    )
  }), [formData.form_fields])
  if(isEmpty(formData.form_fields)) {
    return null
  }
  return (
    <Container size="xl" className={cx(!withoutPadding && classNames.wrapper)} isPadding={!withoutPadding}>
      <FormSpy
        subscription={{ submitFailed: true }}
        onChange={detectError}
      />
      {title && (
        <Typography tag="div" variant="h2" className={cx(classNames.formTitle, (!subtitle && !compactTitle) && classNames.withMargin, compactTitle && classNames.compactTitle)}>{title}</Typography>
      )}
      {subtitle && (
        <Typography tag="div" variant="content" className={classNames.formSubtitle}>{subtitle}</Typography>
      )}
      <Row gutter={[10, 10]} align="start" className={columnAlign && classNames.columnAlign}>
        {fieldData}
        {handleSubmit && (
          <Col span={24}>
            <Button
              onClick={handleSubmit}
              disabled={!valid || isLoading}
              isLoading={isLoading}
            >
              {data.button_label || formData.button_label || gettext('Submit')}
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  )
}
