import { connect } from 'react-redux'
import get from 'lodash/get'
import { compose } from 'redux'

import CartView from './CartView'
import withMainBannerHOC from 'hocs/withMainBannerHOC'
import withCart from 'hocs/withCartHOC'


export default compose(
  withMainBannerHOC,
  connect((state) => ({
    schoolName: get(state, 'schoolNewServices.data.name', ''),
  })),
  withCart,
)(CartView)
