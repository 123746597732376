import { Fragment, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import cx from 'classnames'
import { ResourceType } from '@ds-frontend/resource'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { Typography, Loading } from 'common/widgets'
import Forms from 'common/widgets/blocks/Forms/FormsView'
import PartyOption from './PartyOption'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'


QuoteForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  valid: PropTypes.bool.isRequired,
  quoteForm: ResourceType.isRequired,
  thirdParty: ResourceType.isRequired,
  values: PropTypes.object,
}

QuoteForm.defaultProps = {
  values: {},
}

export default function QuoteForm({ handleSubmit, quoteForm, valid, thirdParty, values, form }) {
  const { gettext } = useTranslations()
  useEffect(() => {
    if(values.optional_email) {
      form.change('required_email', values.optional_email)
    }
  }, [values.optional_email])
  useEffect(() => {
    if(values.required_email) {
      form.change('optional_email', values.required_email)
    }
  }, [values.required_email])
  return (
    <Fragment>
      {!isEmpty(thirdParty.data) && (
        <Fragment>
          <Typography tag="div" variant="h2" transform="uppercase">{gettext('Additional Services')}</Typography>
          {thirdParty.data.map((item, index) => (
            <PartyOption
              key={index}
              name={`third_party_services[${index}]`}
              item={item}
            />
          ))}
        </Fragment>
      )}
      <Typography tag="div" variant="h2" transform="uppercase">{gettext('Offer')}</Typography>
      <div className={classNames.formBlock}>
        <Loading isLoading={quoteForm.isLoading} />
        <div className={cx(classNames.formRow, quoteForm.isLoading && classNames.loading)}>
          <Forms formData={{ form_fields: get(quoteForm, 'data.form_fields', []) }} handleSubmit={handleSubmit} valid={valid} />
        </div>
      </div>
    </Fragment>
  )
}
