import { withFinalForm, prefetchResources } from '@ds-frontend/resource'
import { compose } from 'redux'
import { connect } from 'react-redux'
import uniq from 'lodash/uniq'
import omit from 'lodash/omit'

import QuoteFormView from './QuoteFormView'
import { withRouter } from 'next/router'
import { Loading } from 'common/widgets'
import withCartHOC from 'hocs/withCartHOC'


export default compose(
  withCartHOC,
  withRouter,
  connect((_, props) => {
    const cartDataList = Object.values(props.cartData.data)
    const language = cartDataList.reduce((sum, current) => {
      const languages = current.course_cards.map(i => i.language_id)
      return [...sum, ...languages]
    }, [])
    return {
      cartDataList,
      language_id: uniq(language),
    }
  }),
  prefetchResources({
    namespace: 'thirdParty',
    endpoint: 'api/v2/public/third-party-services',
    queries: ['language_id'],
    reducer: 'replace',
  }, {
    Loader: props => <Loading {...props} />,
  }),
  withFinalForm({
    onSubmit: (data, _, props) => {
      const formattedCart = props.cartDataList.map(i => ({
        school_slug: i.school.slug,
        option: i,
      }))
      const services = data.third_party_services.filter(i => i.is_selected)
      return props.quoteForm.create({
        ...omit(data, 'third_party_services'),
        quote_data: {
          third_party_services: services,
          options: formattedCart,
        },
      })
        .then(resp => {
          props.cartData.setParams({
            clearOnNextMount: true,
          })
          const language = props.languageCode === 'de' ? '' : `/${props.languageCode}`
          location.href = `${language}/quote/list/${resp.id}`
        })
    },
    initialValues: {
      third_party_services: [],
    },
  },
  {
    endpoint: 'api/v1/crm/forms/quote-form',
    namespace: 'quoteForm',
  }, { Loader: props => <Loading {...props} /> })
)(QuoteFormView)
