import { Component } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { autobind } from 'core-decorators'
import API from 'api'


import {
  parseElasticKeyData,
} from 'common/utils/elastic'
import SchoolFiltersView from './SchoolFiltersView'


export default class SchoolFiltersContainer extends Component {
  state = {
    languageData: [],
    countryData: [],
    courseCategoryData: [],
  }

  @autobind
  getOptions({ values }) {
    if(!process.env.SSR) {
      API.post('api/v1/search/maps', values)
        .then(resp => {
          const languageData = parseElasticKeyData(get(
            resp,
            'aggregations.languages',
            []
          ))
          const countryData = parseElasticKeyData(map(get(
            resp,
            'aggregations.destinations',
            []
          ), 'country'))
          const courseCategoryData = parseElasticKeyData(map(get(
            resp,
            'aggregations.course_types',
            []
          ), 'category'))
          this.setState({
            languageData,
            countryData,
            courseCategoryData,
          })
        })
    }
  }

  render() {
    return (
      <SchoolFiltersView
        {...this.props}
        {...this.state}
        getOptions={this.getOptions}
      />
    )
  }
}
