import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { FormSpy } from 'react-final-form'
import { QS } from 'api'

import { SelectField } from 'common/forms'
import { Button, Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'
import omit from 'lodash/omit'


SchoolFiltersView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  languageData: PropTypes.array.isRequired,
  countryData: PropTypes.array.isRequired,
  courseCategoryData: PropTypes.array.isRequired,
  getOptions: PropTypes.func.isRequired,
  heading: PropTypes.string,
  headingFontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  btnCaption: PropTypes.string,
  values: PropTypes.object,
}

SchoolFiltersView.defaultProps = {
  submitting: false,
  heading: undefined,
  headingFontSize: undefined,
  btnCaption: undefined,
  values: {},
}

export default function SchoolFiltersView({
  submitting,
  languageData,
  countryData,
  courseCategoryData,
  heading,
  headingFontSize,
  btnCaption,
  getOptions,
  values,
}) {
  const { gettext, languageCode } = useTranslations()
  return (
    <Fragment>
      <FormSpy
        subscription={{ values: true }}
        onChange={getOptions}
      />
      {heading && (
        <Typography variant="h2" color="white" className={classNames[headingFontSize]}>{heading}</Typography>
      )}
      <Row gutter={8}>
        <Col span={24} md={12} lg={6}>
          <SelectField
            name="languages"
            instanceId={'filters-view-all-languages'}
            valueKey="uuid"
            labelKey="name"
            size="large"
            isMulti
            placeholder={gettext('All Languages')}
            aria-label={gettext('All Languages')}
            options={languageData}
            className={classNames.select}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isLargeFont
            countKey="count"
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <SelectField
            name="destinations"
            instanceId={'filters-view-all-countries'}
            valueKey="uuid"
            labelKey="name"
            size="large"
            isMulti
            placeholder={gettext('All Countries')}
            aria-label={gettext('All Countries')}
            options={countryData}
            className={classNames.select}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isLargeFont
            countKey="count"
          />
        </Col>
        <Col span={24} md={12} lg={7} xl={7}>
          <SelectField
            name="course_types"
            instanceId={'filters-view-all-categories'}
            valueKey="uuid"
            labelKey="name"
            size="large"
            isMulti
            placeholder={gettext('All Course Categories')}
            aria-label={gettext('All Course Categories')}
            options={courseCategoryData}
            className={classNames.select}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isLargeFont
            countKey="count"
          />
        </Col>
        <Col span={24} md={12} lg={5} xl={5}>
          <Button
            isLoading={submitting}
            disabled={submitting}
            className={classNames.btn}
            tag="a"
            href={`/${languageCode}/school-finder?${QS.buildQueryParams(omit(values, ['size']))}`}
          >
            {btnCaption || gettext('Search')}
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}
