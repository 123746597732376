import dynamic from 'next/dynamic'
import { Loading } from 'common/widgets'


const Collapse = dynamic(() => import('./Collapse'), { loading: () => <Loading isLoading /> })
const Panel = dynamic(() => import('./Panel'), { loading: () => <Loading isLoading /> })

export {
  Collapse,
  Panel,
}
