import { components } from 'react-select'
import PropTypes from 'prop-types'

import classNames from './styles.module.scss'


MenuList.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  selectProps: PropTypes.object.isRequired,
}

export default function MenuList(props) {
  return (
    <components.MenuList {...props} className={classNames.menuList} />
  )
}
