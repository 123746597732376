import cx from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import classNames from './styles.module.scss'


ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object.isRequired,
}

export default function ValueContainer({ children, selectProps }) {
  // strange fix for react-select bug with multiple values + ellipsis
  const [ isRtl, setRtl ] = useState(false)
  useEffect(() => {
    if(selectProps.isMulti && selectProps.menuIsOpen) {
      setRtl(true)
      setTimeout(() => {
        setRtl(false)
      })
    }
  }, [selectProps.menuIsOpen, selectProps.isMulti])
  return (
    <div data-testid={selectProps['data-testid']} className={cx(classNames.valueContainer, isRtl && classNames.rtl, 'valueContainer')}>{children}</div>
  )
}
