import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import noop from 'lodash/noop'
import { Form } from 'react-final-form'
import cx from 'classnames'

import classNames from './styles.module.scss'
import ServiceCard from './ServiceCard'
import PromotionCard from './PromotionCard'
import { Typography, Divider, Button } from 'common/widgets'
import { TextField } from 'common/forms'
import { useTranslations, interpolate } from 'common/language'
import formatPrice from 'common/utils/formatPrice'


QuoteCard.propTypes = {
  option: PropTypes.shape({
    courses: PropTypes.array,
    add_ons: PropTypes.array,
    accommodations: PropTypes.array,
    third_party_services: PropTypes.array,
    transfers: PropTypes.array,
    promotions: PropTypes.array,
    school_name: PropTypes.string,
    school_fees: PropTypes.object,
    boa_lingua_fees: PropTypes.object,
    school_extra_positions: PropTypes.object,
    boa_lingua_extra_positions: PropTypes.object,
    school_total: PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      reference: PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        currency: PropTypes.string,
        exchange_rate: PropTypes.number,
      }),
    }),
    school_promotions: PropTypes.shape({
      items: PropTypes.array,
    }),
    boa_lingua_promotions: PropTypes.shape({
      items: PropTypes.array,
    }),
    promotion_savings: PropTypes.shape({
      items: PropTypes.array,
    }),
    overall_total: PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      reference: PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        currency: PropTypes.string,
      }),
    }),
  }).isRequired,
  children: PropTypes.node,
  isSchoolName: PropTypes.bool,
  hidePartyOptions: PropTypes.bool,
  handleVoucher: PropTypes.func,
  initialVoucher: PropTypes.string,
  withVoucher: PropTypes.bool,
}

QuoteCard.defaultProps = {
  children: null,
  isSchoolName: false,
  hidePartyOptions: false,
  handleVoucher: noop,
  initialVoucher: null,
  withVoucher: false,
}

export default function QuoteCard({ option, children, isSchoolName, hidePartyOptions, handleVoucher, initialVoucher, withVoucher }) {
  const { gettext } = useTranslations()
  const {
    courses,
    add_ons: addons,
    accommodations,
    transfers,
    school_promotions: schoolPromotions,
    boa_lingua_promotions: boaPromotions,
    promotion_savings: promotionSavings,
    school_name: schoolName,
    school_fees: schoolFees,
    boa_lingua_fees: boaLinguaFees,
    third_party_services: thirdPartyServices,
    school_extra_positions: schoolExtraPositions,
    boa_lingua_extra_positions: boaLinguaExtraPositions,
  } = option
  const exchangeRate = useMemo(() => get(option, 'exchange_rate.value', '').toString().replace('.', ','), [get(option, 'exchange_rate.value')])
  const handleEnter = submit => e => e?.key === 'Enter' && e?.target.value?.length > 0 && submit()
  const handleDeleteVoucher = form => () => {
    form.change('voucher_code', null)
    form.submit()
  }
  return (
    <Fragment>
      {isSchoolName && schoolName && (
        <div className={classNames.schoolDesktopName}>
          <Typography
            color="black"
            variant="quote-card-title"
            tag="div"
          >
            {schoolName}
          </Typography>
        </div>
      )}
      {!isEmpty(get(schoolFees, 'items', [])) && (
        <ServiceCard type={schoolFees} />
      )}
      {(!isEmpty(get(schoolFees, 'items', [])) || isSchoolName) && (
        <Divider className={classNames.divider} />
      )}
      {courses && courses.map((type, index) => (
        <ServiceCard key={index} type={type} />
      ))}
      {addons && addons.map((type, index) => (
        <ServiceCard key={index} type={type} contentClassName={classNames.noMargin} />
      ))}
      {accommodations && accommodations.map((type, index) => (
        <ServiceCard key={index} type={type} />
      ))}
      {transfers && transfers.map((type, index) => (
        <ServiceCard key={index} type={type} />
      ))}
      <Divider className={classNames.divider} />
      {!isEmpty(get(schoolExtraPositions, 'items')) && (
        <ServiceCard type={schoolExtraPositions} />
      )}
      {!isEmpty(get(schoolExtraPositions, 'items')) && (
        <Divider className={classNames.divider} />
      )}
      {!isEmpty(get(schoolPromotions, 'items', [])) && (
        <Fragment>
          <PromotionCard promotions={get(schoolPromotions, 'items', [])} />
          <Divider className={classNames.divider} />
        </Fragment>
      )}
      <div className={classNames.textRow} data-testid="school total">
        <span>
          <Typography variant="small-gray-cart" transform="uppercase">
            <span>
              {interpolate(gettext('total %s'), [get(option, 'school_total.currency', '')])}
            </span>
          </Typography>
        </span>
        <span>
          <Typography variant="small-gray-cart" transform="uppercase">
            <span>
              {`${formatPrice(get(option, 'school_total.value', ''))} ${get(option, 'school_total.currency', '')}`}
            </span>
          </Typography>
        </span>
      </div>
      {!isEmpty(get(option, 'school_total.reference')) && (
        <Fragment>
          <div className={classNames.textRow}>
            <span>
              <Typography variant="small-gray-cart" transform="uppercase">
                <span>
                  {interpolate(gettext('total %s'), [get(option, 'school_total.reference.currency', '')])}
                </span>
              </Typography>
            </span>
            <span>
              <Typography variant="small-gray-cart" transform="uppercase">
                <span>
                  {`${formatPrice(get(option, 'school_total.reference.value', ''))} ${get(option, 'school_total.reference.currency', '')}`}
                </span>
              </Typography>
            </span>
          </div>
          <Typography variant="small-gray-cart">
            {interpolate(gettext('Exchange Rate %s'), [exchangeRate])}
          </Typography>
        </Fragment>
      )}
      <Divider className={classNames.divider} />
      {!hidePartyOptions && thirdPartyServices && thirdPartyServices.map((type, index) => (
        <Fragment key={index}>
          <ServiceCard type={type} contentClassName={classNames.noMargin} />
          {index === thirdPartyServices.length - 1 && (
            <Divider className={classNames.divider} />
          )}
        </Fragment>
      ))}
      {!isEmpty(get(boaLinguaFees, 'items', [])) && (
        <ServiceCard type={boaLinguaFees} />
      )}
      {!isEmpty(get(boaLinguaExtraPositions, 'items')) && (
        <Divider className={classNames.divider} />
      )}
      {!isEmpty(get(boaLinguaExtraPositions, 'items')) && (
        <ServiceCard type={boaLinguaExtraPositions} />
      )}
      {(!isEmpty(get(boaLinguaExtraPositions, 'items')) || !isEmpty(get(boaLinguaFees, 'items'))) && (
        <div className={classNames.spacing} />
      )}
      {!isEmpty(get(boaPromotions, 'items', [])) && (
        <PromotionCard promotions={get(boaPromotions, 'items', [])} />
      )}
      <div className={cx(classNames.textRow, isEmpty(get(promotionSavings, 'items', [])) && classNames.marginBottom)}>
        <span>
          <Typography variant="h4-card" transform="uppercase">
            {gettext('overall total')}
          </Typography>
        </span>
        <span>
          <Typography variant="h4-card" transform="uppercase">
            {hidePartyOptions ? (
              `${formatPrice(get(option, 'total_without_third_party_services.value', ''))} ${get(option, 'total_without_third_party_services.currency', '')}`
            ) : (
              `${formatPrice(get(option, 'overall_total.value', ''))} ${get(option, 'overall_total.currency', '')}`
            )}
          </Typography>
        </span>
      </div>
      {!isEmpty(get(promotionSavings, 'items', [])) && (
        <PromotionCard promotions={get(promotionSavings, 'items', [])} withMargin savings />
      )}
      {withVoucher && (
        <div className={classNames.voucherField}>
          <Form onSubmit={handleVoucher} initialValues={{ voucher_code: initialVoucher }}
            render={(fieldProps) => {
              return (
                <Fragment>
                  <div>
                    <TextField
                      size="smallPlaceholder"
                      name="voucher_code"
                      label={gettext('Enter Voucher Code')}
                      onKeyDown={handleEnter(fieldProps.form.submit)}
                      disabled={Boolean(initialVoucher)}
                    />
                    <Button
                      type="icon"
                      icon="fal fa-times"
                      className={cx(classNames.closeIcon, initialVoucher && classNames.display)}
                      onClick={handleDeleteVoucher(fieldProps.form)}
                    />
                  </div>
                  <Button
                    onClick={fieldProps.form.submit}
                    disabled={!fieldProps?.values?.voucher_code || Boolean(initialVoucher)}
                    className={classNames.btn}
                    isLoading={fieldProps?.submitting}
                  >{gettext('Enter')}</Button>
                </Fragment>
              )
            }}
          />
        </div>
      )}
      {children}
    </Fragment>
  )
}
