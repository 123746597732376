import get from 'lodash/get'


export function parseElasticKeyData(data = []) {
  return data.map(item => {
    const data = item.key.split(';;')
    const count = item.doc_count || 0
    return {
      uuid: data[0],
      name: `${data[1]} (${count})`,
      count: count,
    }
  })
}

export function parseElasticNestedData({ data = [], parentPath = '', nestedPath = '' }) {
  return data.map(item => {
    const parent = get(item, parentPath, '')
    const parentData = parent.key.split(';;')
    const parentCount = parent.doc_count || 0
    const nestedData = get(item, nestedPath, [])
      .map(i => {
        const child = i.key.split(';;')
        const childCount = i.doc_count || 0
        return {
          uuid: child[0],
          name: `${child[1]} (${childCount})`,
          count: childCount,
        }
      })
    return {
      uuid: parentData[0],
      name: `${parentData[1]} (${parentCount})`,
      count: parentCount,
      data: nestedData,
    }
  })
}
