import { compose } from 'redux'
import { withFinalForm } from '@ds-frontend/resource'
import omit from 'lodash/omit'

import { withRouter } from 'next/router'
import SchoolFiltersContainer from './SchoolFiltersContainer'


export default compose(
  withRouter,
  withFinalForm(
    {
      initialValues: {
        languages: [],
        destinations: [],
        course_types: [],
        size: 0,
      },
      onSubmit: (data, _, props) => {
        props.router.push({
          pathname: `/${props.locale}/school-finder`,
          query: { ...omit(data, ['size']), id: 'school-finder' },
        })
      },
    },
    'filtersForm',
    { prefetch: false }
  )
)(SchoolFiltersContainer)
