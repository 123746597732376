import { Fragment } from 'react'

import { Typography, SchoolFilters } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'


export default function AddAnotherOption() {
  const { gettext } = useTranslations()
  return (
    <Fragment>
      <Typography
        tag="div"
        variant="h2"
        transform="uppercase"
      >
        {gettext('Add another option')}
      </Typography>
      <div className={classNames.formWrapper}>
        <div className={classNames.formRow}>
          <SchoolFilters />
        </div>
      </div>
    </Fragment>
  )
}
