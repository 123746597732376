import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Collapse, Panel, CardSlider, Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations, interpolate } from 'common/language'


HotLeisureTips.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    distance: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    heading: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.array,
  })),
  schoolName: PropTypes.string,
}

HotLeisureTips.defaultProps = {
  data: [],
  schoolName: 'Test School',
}

export default function HotLeisureTips({ data, schoolName }) {
  const { gettext } = useTranslations()
  const panels = useMemo(() => (
    data.map((tip, idx) => (
      <Panel
        key={idx}
        isClassless
        header={
          <div className={classNames.leisureHeader}>
            <b>{tip.heading}</b>
            {tip.distance !== undefined && `(${interpolate(gettext('%s km from %s'), [tip.distance, schoolName])})`}
          </div>
        }
        className={classNames.leisurePanel}
      >
        <Row gutter={[{ md: 0, lg: 19 }, { xs: 8, sm: 8, md: 8, lg: 0 }]}>
          <Col xl={7} lg={9} xs={24}>
            <CardSlider images={tip.images} />
          </Col>
          <Col xl={17} lg={15} xs={24}>
            <Typography
              variant="collapse-content"
              color="light-black"
            >
              {tip.text}
            </Typography>
          </Col>
        </Row>
      </Panel>
    ))
  ), [data])
  return (
    <Collapse className={classNames.leisureCollapse} accordion defaultActiveKey={['0']}>
      {panels}
    </Collapse>
  )
}
