import get from 'lodash/get'

import emptyPlaceholder from 'img/empty-placeholder.svg'


export default function getImgFromBynder(image = {}, formats = []) {
  let url = ''
  formats.forEach(format => {
    const path = get(image, `files.${format}.url`, get(image, `derivatives.${format}`))
    if(!url && path) {
      url = path
    }
  })
  return url || emptyPlaceholder
}
