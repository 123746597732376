import React, { useMemo, Fragment, useCallback, forwardRef } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from 'common/router/Link'
import classNames from './styles.module.scss'


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  isLoading: PropTypes.bool,
  spin: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconBadge: PropTypes.number,
  fullWidth: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  classNameIcon: PropTypes.string,
  isModal: PropTypes.bool,
  locale: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  prefetch: PropTypes.bool,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  passHref: PropTypes.bool,
}

const defaultProps = {
  children: undefined,
  spin: true,
  type: 'primary',
  className: undefined,
  isLoading: false,
  onClick: noop,
  to: undefined,
  disabled: false,
  tag: 'button',
  icon: undefined,
  fullWidth: false,
  classNameIcon: undefined,
  iconBadge: undefined,
  isModal: false,
  locale: undefined,
  as: undefined,
  prefetch: undefined,
  passHref: false,
}

const ButtonComponent = forwardRef(({ children, type, className, isLoading, onClick, disabled, tag, icon, classNameIcon, to, fullWidth, spin, iconBadge, isModal, locale, as, prefetch, passHref, ...props }, ref) => {
  const btnClassName = useMemo(() => cx(
    className,
    classNames[type],
    classNames.button,
    isLoading && classNames.loading,
    disabled && classNames.disabled,
    fullWidth && classNames.fullWidth,
  ), [type, className, isLoading, disabled])
  const debounceClick = useCallback(e => {
    e.stopPropagation();
    (isModal || tag === 'a') ? onClick(e) : debounce(onClick, 100)(e)
  }, [onClick, tag, onClick])
  const Wrapper = to ? Link : Fragment
  const linkProps = useMemo(() => {
    if(to) {
      return { href: to, locale, as, prefetch, passHref }
    }
    return {}
  }, [to, locale, as, prefetch, passHref])
  return (
    <Wrapper {...linkProps}>
      {React.createElement(
        tag,
        {
          className: btnClassName,
          onClick: debounceClick,
          disabled: disabled || isLoading,
          ref,
          ...props,
        },
        <Fragment>
          <div className={cx(classNames.content, isLoading && classNames.hidden)}>
            {icon && <span className={classNames.iconWrapper}>
              <FontAwesomeIcon className={cx(classNames.buttonIcon, classNameIcon)} icon={icon} />
              {iconBadge !== undefined && (
                <span className={classNames.iconBadge}>{iconBadge}</span>
              )}
            </span>}
            {children}
          </div>
          {spin
            ? <Spin className={classNames.spin} indicator={antIcon} />
            : <Fragment />}
        </Fragment>
      )}
    </Wrapper>
  )
})

ButtonComponent.propTypes = propTypes
ButtonComponent.defaultProps = defaultProps

export default ButtonComponent
