import PropTypes from 'prop-types'

import classNames from './styles.module.scss'
import { Typography, Info } from 'common/widgets'


SubsectionField.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
}

SubsectionField.defaultProps = {
  tooltip: undefined,
}

export default function SubsectionField({ label, tooltip, ...rest }) {
  return (
    <Typography {...rest} variant="h4" transform="uppercase" className={classNames.subsectionField}>
      {label}
      {tooltip && <Info title={tooltip} className={classNames.info} />}
    </Typography>
  )
}
