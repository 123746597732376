import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classNames from './styles.module.scss'


export default function DropdownIndicator() {
  return (
    <FontAwesomeIcon className={classNames.indicator} icon="sort-down" />
  )
}
