import { withFinalForm, prefetchResources } from '@ds-frontend/resource'
import { compose } from 'redux'
import { connect } from 'react-redux'
import uniq from 'lodash/uniq'

import ContinueBookingView from './ContinueBookingView'
import { withRouter } from 'next/router'
import { Loading } from 'common/widgets'
import withCart from 'hocs/withCartHOC'


export default compose(
  withRouter,
  withCart,
  connect((_, props) => {
    const language = props.cart.course_cards.map(i => i.language_id).filter(i => Boolean(i))
    return {
      language_id: uniq(language),
    }
  }),
  prefetchResources({
    namespace: 'thirdParty',
    endpoint: 'api/v2/public/third-party-services',
    queries: ['language_id'],
    reducer: 'replace',
  }, {
    Loader: props => <Loading {...props} />,
  }),
  withFinalForm({
    onSubmit: (data, _, props) => {
      const services = data.third_party_services.filter(i => i.is_selected)
      return props.bookingForm.create({
        ...props.cart,
        id: props.cart.school.slug,
        third_party_services: services,
      })
        .then(resp => {
          props.cartData.setData({
            ...props.cartData.data,
            [props.state.id]: {
              ...props.cartData.data[props.state.id],
              ...resp,
              locale: props.router.locale,
            },
          })
          props.setBookingData({ ...props.state, cart: { ...resp, locale: props.router.locale } })
          props.router.push('/booking')
        })
    },
    initialValues: {
      third_party_services: [],
    },
  },
  {
    endpoint: 'api/v2/public/schools/:id/application-options',
    namespace: 'bookingForm',
  }, { prefetch: false })
)(ContinueBookingView)
