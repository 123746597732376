import useLocalStorage from 'hooks/useLocalStorage'
import { createContext, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'


export const Cart = createContext({})

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function CartProvider({ children }) {
  const [ data, setData ] = useLocalStorage('cartData', {})
  const [ params, setParams ] = useLocalStorage('cartDataParams', {})
  const [ isCartLoading, setCartLoading ] = useState(false)

  const clear = useCallback(() => {
    setData({})
    setParams({})
  }, [setData, setParams])

  useEffect(() => {
    if(params.clearOnNextMount) {
      clear()
    }
  }, [])

  const setCartParams = useCallback((newParams = {}) => {
    setParams({ ...params, ...newParams })
  }, [setParams, params])

  return (
    <Cart.Provider value={{ data, setData, setParams: setCartParams, clear, isCartLoading, setCartLoading }}>
      {children}
    </Cart.Provider>
  )
}
