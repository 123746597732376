import { useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'


BaseFieldLayout.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  withoutError: PropTypes.bool,
  inputComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  prefix: PropTypes.node,
  size: PropTypes.string,
}

BaseFieldLayout.defaultProps = {
  label: undefined,
  required: false,
  prefix: undefined,
  withoutError: false,
  size: 'medium',
}

export default function BaseFieldLayout({
  label,
  prefix,
  required,
  inputComponent: InputComponent,
  meta,
  input,
  withoutError,
  size,
  ...rest
}) {
  const error = useMemo(() => {
    if(meta.submitError && !meta.dirtySinceLastSubmit) {
      return meta.submitError
    }
    if(meta.error && meta.touched) {
      return meta.error
    }
  }, [meta.error, meta.touched, meta.dirtySinceLastSubmit, meta.submitError])
  const formattedError = useMemo(() => Array.isArray(error) ? error[0] : error, [error])
  return (
    <div className={cx('form-group', meta.submitError && !meta.dirtySinceLastSubmit && 'is-submit-error')}>
      {label && (
        <label className={classNames.baseLabel}>
          {label}
          {required && <span className="control-asterisk"> * </span>}
        </label>
      )}
      <div className="control-field">
        <div className="control-element">
          {prefix && <div className="control-prefix">{prefix}</div>}
          <InputComponent
            required={required}
            error={error}
            size={size}
            {...rest}
            {...input}
          />
          {!withoutError && (
            <Typography variant="error" color="pink" className={cx(classNames.customError, size === 'xlarge' && classNames.largeError)}>
              {formattedError && (
                <Fragment>
                  <FontAwesomeIcon icon="exclamation-circle" className={classNames.errorCircle} />
                  {formattedError}
                </Fragment>
              )}
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}
