import Link from 'next/link'
import { withRouter } from 'next/router'
import React, { Children } from 'react'
import cx from 'classnames'


export default withRouter(({ router, children, className, as, href, activeClassName, passHref, prefetch, ...rest }) => {
  const child = Children.only(children)
  return (
    <Link {...rest} href={href} prefetch={prefetch} passHref={passHref} as={as}>
      {React.cloneElement(child, {
        className: cx(child.props.className, (router.asPath === href?.pathname || router.asPath === href || router.asPath === as) && activeClassName),
        ...rest,
      })}
    </Link>
  )
})
