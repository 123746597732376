import { useCallback, useMemo, useState, Fragment } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import OptimizedOption from './OptimizedOption'
import MenuList from './MenuList'
import MultiValue from './MultiValue'
import MultiValueRemove from './MultiValueRemove'
import classNames from './styles.module.scss'
import DropdownIndicator from './DropdownIndicator'
import ClearIndicator from './ClearIndicator'
import ValueContainer from './ValueContainer'
import { useTranslations } from 'common/language'


SelectInput.propTypes = {
  value: PropTypes.any,
  instanceId: PropTypes.string,
  isSearchable: PropTypes.bool,
  valueKey: PropTypes.string,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  components: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isLargeFont: PropTypes.bool,
  isSmallFont: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  boldValue: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
}

SelectInput.defaultProps = {
  isClearable: false,
  isMulti: false,
  isSearchable: false,
  valueKey: 'value',
  labelKey: 'label',
  className: undefined,
  value: undefined,
  instanceId: undefined,
  options: [],
  components: {},
  disabled: false,
  isLargeFont: false,
  isSmallFont: false,
  error: false,
  boldValue: false,
  size: 'medium',
  placeholder: undefined,
  noOptionsMessage: undefined,
}


// !important for rewriting react-select default outline: none!important
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    outlineWidth: state.isFocused && '1px !important',
    outlineOffset: state.isFocused && '0px !important',
    outlineStyle: state.isFocused && 'solid !important',
    outlineColor: state.isFocused && '#BFBF0F !important',
    borderColor: state.isFocused && '#BFBF0F !important',
    transitionProperty: 'border-color outline-width',
  }),
}

export default function SelectInput({
  onChange,
  value,
  instanceId,
  isSearchable,
  valueKey,
  labelKey,
  className,
  options,
  components,
  isMulti,
  isClearable,
  disabled,
  error,
  size,
  placeholder,
  isLargeFont,
  isSmallFont,
  noOptionsMessage,
  ...restProps
}) {
  const { gettext } = useTranslations()
  const handleChange = useCallback((e) => {
    if(!e) {
      return onChange(isMulti ? [] : null)
    }
    if(isMulti) {
      const multiValue = e.map(value => get(value, valueKey))
      return onChange(multiValue)
    }
    onChange(get(e, valueKey))
  }, [onChange, valueKey])
  const generateValue = useCallback((e) => {
    if(isMulti) {
      let value = e
      if(!value) {
        return []
      }
      if(!Array.isArray(value)) {
        value = [value]
      }
      return value.map(singleValue => options.find(i => get(i, valueKey) === singleValue) || null)
    }
    return options.find(i => isEqual(get(i, valueKey), e)) || null
  }, [valueKey, options, value])
  const [isOpen, setOpen] = useState(false)
  const onMenuClose = () => {
    restProps.onMenuClose && restProps.onMenuClose()
    setOpen(false)
  }
  const onMenuOpen = () => {
    restProps.onMenuOpen && restProps.onMenuOpen()
    setOpen(true)
  }

  const selectComponents = useMemo(_ => {
    const selectComponents = {
      Option: OptimizedOption,
      MultiValue,
      MultiValueRemove,
      IndicatorSeparator: null,
      MenuList,
      DropdownIndicator: disabled ? null : DropdownIndicator,
      ClearIndicator: ClearIndicator,
      ValueContainer: ValueContainer,
      ...components,
    }
    return (selectComponents)
  }, [disabled])
  return (
    // https://github.com/JedWatson/react-select/issues/3929
    <Fragment>
      {
        isOpen && (
          <div className={classNames.layout} />
        )
      }
      <Select
        instanceId={instanceId}
        options={options}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        value={generateValue(value)}
        isMulti={isMulti}
        onChange={handleChange}
        noOptionsMessage={noOptionsMessage}
        className={cx(
          classNames.select,
          className,
          isMulti && classNames.multiSelect,
          disabled && classNames.disabled,
          error && classNames.error,
          classNames[size],
          isLargeFont && classNames.largeFont,
          isSmallFont && classNames.smallFont,
        )}
        components={selectComponents}
        getOptionLabel={opt => get(opt, labelKey)}
        getOptionValue={opt => get(opt, valueKey)}
        isSearchable={isSearchable}
        styles={customStyles}
        isClearable={isClearable}
        isDisabled={disabled}
        placeholder={placeholder === undefined ? gettext('Select') : placeholder}
        {...restProps}
      />
    </Fragment>
  )
}
