import baseFieldHOC from './BaseFieldHOC'

import CheckboxInput, { CheckboxArrayInput } from './inputs/CheckboxInput'
import NumberInput from './inputs/NumberInput'
import RadioInput from './inputs/RadioInput'
import TextInput from './inputs/TextInput'
import TextAreaInput from './inputs/TextAreaInput'
import SelectInput from './inputs/SelectInput'
import DateInput from './inputs/DateInput'
import SliderInput from './inputs/SliderInput'
import PhoneInput from './inputs/PhoneInput'
import SwitchInput from './inputs/SwitchInput'
import MaskTimeInput from './inputs/TimePickerInput'


const CheckboxField = baseFieldHOC(CheckboxInput)
const CheckboxArrayField = baseFieldHOC(CheckboxArrayInput)
const NumberField = baseFieldHOC(NumberInput)
const RadiosField = baseFieldHOC(RadioInput)
const TextField = baseFieldHOC(TextInput, true)
const TextAreaField = baseFieldHOC(TextAreaInput, true)
const SelectField = baseFieldHOC(SelectInput, true)
const DateField = baseFieldHOC(DateInput, true)
const SliderField = baseFieldHOC(SliderInput)
const PhoneField = baseFieldHOC(PhoneInput, true)
const SwitchField = baseFieldHOC(SwitchInput)
const MaskTimeField = baseFieldHOC(MaskTimeInput, true)


export {
  CheckboxField,
  NumberField,
  RadiosField,
  TextField,
  TextAreaField,
  SelectField,
  DateField,
  SliderField,
  CheckboxArrayField,
  PhoneField,
  SwitchField,
  MaskTimeField,
}
