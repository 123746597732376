import { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'
import isEmpty from 'lodash/isEmpty'

import { Typography, Button } from 'common/widgets'
import PartyOption from './PartyOption'
import { useTranslations } from 'common/language'


ContinueBookingView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  thirdParty: ResourceType.isRequired,
  bookingForm: ResourceType.isRequired,
}

export default function ContinueBookingView({ handleSubmit, thirdParty, bookingForm }) {
  const { gettext } = useTranslations()
  return (
    <Fragment>
      {!isEmpty(thirdParty.data) && (
        <Fragment>
          <Typography tag="div" variant="h2" transform="uppercase">{gettext('Additional Services')}</Typography>
          {thirdParty.data.map((item, index) => (
            <PartyOption
              key={index}
              name={`third_party_services[${index}]`}
              item={item}
            />
          ))}
        </Fragment>
      )}
      <div>
        <Button
          onClick={handleSubmit}
          disabled={bookingForm.isLoading}
          isLoading={bookingForm.isLoading}
        >
          {gettext('Continue')}
        </Button>
      </div>
    </Fragment>
  )
}
