import { components } from 'react-select'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classNames from './styles.module.scss'


MultiValueRemove.propTypes = {
  innerProps: PropTypes.object,
}

MultiValueRemove.defaultProps = {
  innerProps: {},
}


export default function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{ ...props.innerProps, className: classNames.removeWrapper }}
    >
      <FontAwesomeIcon icon="times" className={classNames.remove} />
    </components.MultiValueRemove>
  )
}
