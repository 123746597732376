import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'


CTALink.propTypes = {
  cta: PropTypes.string,
  ctaURL: PropTypes.string,
  icon: PropTypes.string,
  isUnderline: PropTypes.bool,
  className: PropTypes.string,
}

CTALink.defaultProps = {
  cta: '',
  ctaURL: undefined,
  icon: undefined,
  isUnderline: false,
  className: undefined,
}

export default function CTALink({ cta, ctaURL, icon, isUnderline, className }) {
  const { gettext } = useTranslations()
  if(!ctaURL) {
    return null
  }
  return (
    <Link href={ctaURL} prefetch={false}>
      <a
        className={cx(classNames.cta, isUnderline && classNames.underline, className)}
      >
        {cta || gettext('View')}
        {icon && <FontAwesomeIcon icon={icon} className={classNames.icon} />}
      </a>
    </Link>
  )
}
