import { useCallback, useMemo } from 'react'
import { Radio } from 'antd'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'


RadioInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array.isRequired,
}

RadioInput.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  value: false,
  disabled: false,
  onChange: undefined,
  className: undefined,
}

export default function RadioInput({ onChange, value, disabled, className, options, labelKey, valueKey, ...rest }) {
  const handleChange = useCallback(e => {
    onChange(e.target.value)
  }, [onChange])
  const radioOptions = useMemo(_ => options.map(
    (option, index) => (
      <Radio
        className={classNames.radioItem}
        value={option[valueKey]}
        key={option[valueKey]}
      >
        <Typography variant="form-radio" data-testid={rest['data-testid'] + ` option ${index}`}>
          {option[labelKey]}
        </Typography>
      </Radio>
    )
  ), [options])
  return (
    <Radio.Group
      onChange={handleChange}
      value={value}
      disabled={disabled}
      className={cx(classNames.wrapper, className)}
    >
      {radioOptions}
    </Radio.Group>
  )
}
