import { components } from 'react-select'

import classNames from './styles.module.scss'


export default function ClearIndicator(props) {
  return (
    <div className={classNames.clearIndicator}>
      <components.ClearIndicator {...props} />
    </div>
  )
}
