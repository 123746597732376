import PropTypes from 'prop-types'
import cx from 'classnames'

import classNames from './styles.module.scss'


Container.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  isPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Container.defaultProps = {
  size: 'lg',
  className: undefined,
  isPadding: true,
}

export default function Container({ size, children, className, isPadding, ...rest }) {
  return (
    <div
      className={cx(classNames[size], isPadding && classNames.padding, className)}
      {...rest}
    >
      {children}
    </div>
  )
}
