import { useMemo, useState } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import Image from 'next/image'

import classNames from './styles.module.scss'
import imgPlaceholder from '@img/noimage.svg'


CardSlider.propTypes = {
  images: PropTypes.array.isRequired,
  className: PropTypes.string,
  priority: PropTypes.bool,
}

CardSlider.defaultProps = {
  className: undefined,
  priority: false,
}

export default function CardSlider({ images, className, priority }) {
  const [order, changeOrder] = useState(0)
  const settings = useMemo(_ => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    afterChange: changeOrder,
  }), [1])
  if(isEmpty(images)) {
    return (
      <div className={cx(classNames.cardImg, classNames.placeholder)}>
        <img src={imgPlaceholder} className={classNames.imgPlaceholder} alt="noImage" />
      </div>
    )
  }
  const sliders = useMemo(_ =>
    images.map((i, index) => (
      <div key={get(i, 'bynder.name')} className={cx(classNames.cardImgBlock, 'card-slide')}>
        <Image
          src={get(i, 'bynder.derivatives.webImage')}
          layout="fill"
          objectFit="cover"
          alt={get(i, 'data.alttext')}
          title={get(i, 'data.name')}
          sizes="(max-width: 1024px) 100vw, (max-width: 1100px) 200px, 271px"
          priority={priority && index === 0}
        />
      </div>
    )
    ), [images])
  return (
    <>
      <div className={classNames.slideWrapper}>
        <div className={classNames.sliderOrdering}>
          {`${order + 1} / ${images.length}`}
        </div>
        <Slider {...settings} className={cx(classNames.cardSlider, className)}>
          {sliders}
        </Slider>
      </div>
      <div className={classNames.printImage}>
        <Image
          src={get(images, '[0].bynder.derivatives.webImage')}
          layout="fill"
          objectFit="cover"
          alt={get(images, '[0].data.alttext')}
          title={get(images, '[0].data.name')}
          sizes="(max-width: 1024px) 100vw, (max-width: 1100px) 200px, 271px"
          priority={priority}
        />
      </div>
    </>
  )
}
