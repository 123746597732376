import PropTypes from 'prop-types'
import Link from 'next/link'
import { Fragment, useCallback, useMemo } from 'react'
import cx from 'classnames'

import { Typography, Button } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations, interpolate } from 'common/language'


OptionHeader.propTypes = {
  school_name: PropTypes.string.isRequired,
  optionUUID: PropTypes.string,
  order: PropTypes.number.isRequired,
  removeOption: PropTypes.func,
  urlData: PropTypes.object,
  withLink: PropTypes.bool,
}

OptionHeader.defaultProps = {
  removeOption: undefined,
  optionUUID: undefined,
  withLink: false,
  urlData: {},
}

export default function OptionHeader({ school_name: schoolName, order, removeOption, withLink, urlData, optionUUID }) {
  const { gettext, languageCode } = useTranslations()
  const optionUUIDObject = useMemo(() => optionUUID ? { option: optionUUID } : {}, [optionUUID])
  const query = useMemo(() => ({
    levels: [urlData?.country_slug, urlData?.destination_slug, urlData?.school_slug],
    ...optionUUIDObject,
  }), [urlData, optionUUID])
  const preventCollapseOpen = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])
  const schoolNameComponent = useMemo(() => (
    <Typography
      variant="h4"
      transform="uppercase"
      color="black"
      className={cx(classNames.subHead, withLink && urlData && classNames.withLink)}
      tag={withLink && urlData ? 'a' : undefined}
    >
      {schoolName}
    </Typography>
  ), [schoolName, withLink, urlData])
  return (
    <Fragment>
      <Typography
        variant="h4"
        transform="uppercase"
        className={classNames.headerTitle}
      >
        {interpolate(gettext('Option %s'), [order])}
        {removeOption && (
          <Button
            type="icon"
            icon={['far', 'trash-alt']}
            onClick={removeOption}
            className={classNames.removeButton}
          />
        )}
      </Typography>
      {withLink && urlData ? (
        <span onClick={preventCollapseOpen}>
          <Link
            href={{
              pathname: '/[...levels]',
              query,
            }}
            locale={languageCode}
          >
            {schoolNameComponent}
          </Link>
        </span>
      ) : schoolNameComponent}
    </Fragment>
  )
}
