import { components } from 'react-select'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import cx from 'classnames'
import { useMemo } from 'react'

import classNames from './styles.module.scss'


const defaultTheme = {
  primary: '#FCFCED',
  primary75: '#FCFCED',
  primary50: '#FCFCED',
  primary25: '#FCFCED',
  neutral0: '#333',
}

OptimizedOption.propTypes = {
  onMouseMove: PropTypes.func,
  onMouseOver: PropTypes.func,
  selectProps: PropTypes.shape({
    isMulti: PropTypes.bool,
    countKey: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

OptimizedOption.defaultProps = {
  onMouseMove: noop,
  onMouseOver: noop,
}

export default function OptimizedOption({
  onMouseMove, onMouseOver, children, theme, isFocused, isDisabled, isSelected, selectProps, data, ...restProps
}) {
  const disabled = useMemo(() => isDisabled || (!isSelected && selectProps.countKey && data[selectProps.countKey] === 0), [isDisabled, isSelected, selectProps.countKey, data])
  return (
    <components.Option
      {...restProps}
      className={cx(
        classNames.option,
        disabled && classNames.disabledOption,
        selectProps.isMulti && classNames.multiOption,
      )}
      theme={{ ...theme, colors: { ...theme.colors, ...defaultTheme } }}
      isDisabled={disabled}
      isFocused={isFocused}
    >
      {selectProps.isMulti ? (
        <div className="ant-checkbox-wrapper">
          <span className={cx('ant-checkbox', isSelected && 'ant-checkbox-checked', disabled && 'ant-checkbox-disabled')}>
            <input type="checkbox" className="ant-checkbox-input" />
            <span className="ant-checkbox-inner" />
          </span>
          <span className={classNames.checkboxLabel}>
            {children}
          </span>
        </div>
      ) : (
        children
      )}
    </components.Option>
  )
}
