import PropTypes from 'prop-types'
import cx from 'classnames'

import classNames from './styles.module.scss'


SquareBlock.propTypes = {
  containerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.node,
}

SquareBlock.defaultProps = {
  containerClassName: undefined,
  innerClassName: undefined,
  children: undefined,
  image: null,
}

export default function SquareBlock({ containerClassName, innerClassName, image, children, ...props }) {
  return (
    <div className={cx(classNames.container, containerClassName)} {...props}>
      {image}
      <div className={cx(classNames.inner, innerClassName)}>
        {children}
      </div>
    </div>
  )
}
