import { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { autobind } from 'core-decorators'
import cx from 'classnames'

import { gettext } from 'common/language'
import classNames from './styles.module.scss'


const propTypes = {
  initialHeight: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isCollapsedDefault: PropTypes.bool,
}

const defaultProps = {
  initialHeight: 120,
  className: undefined,
  isCollapsedDefault: false,
}

export default class CollapseText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: props.initialHeight,
      isCollapsed: props.isCollapsedDefault,
    }
  }

  @autobind
  countLines() {
    let height = this.content.offsetHeight
    if(height > this.props.initialHeight) {
      this.setState({ isCollapsed: true })
    }
  }

  @autobind
  collapseButtonHandler() {
    if(!this.state.height) {
      this.setState({ height: this.props.initialHeight })
    } else {
      this.setState({ height: null })
    }
  }

  componentDidMount() {
    this.countLines()
  }

  render() {
    const { height, isCollapsed } = this.state
    return (
      <div className={this.props.className}>
        <div
          className={cx(classNames.parent, isCollapsed && height && classNames.showMore)}
          style={{ height: height ? `${height}px` : this.content.offsetHeight }}
        >
          <div ref={(c) => {
            this.content = c
          }}
          >
            {this.props.children}
          </div>
        </div>
        {isCollapsed && (
          <button
            className={classNames.showMoreButton}
            onClick={this.collapseButtonHandler}
          >
            {height ? gettext('Learn more') : gettext('Hide')}
            <FontAwesomeIcon
              icon="sort-up"
              className={cx(height && classNames.sortDown)}
            />
          </button>
        )}
      </div>
    )
  }
}

CollapseText.propTypes = propTypes
CollapseText.defaultProps = defaultProps
